body {
  background: #eef2f6;
}

div {
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

a {
  transition: background-color 0.2s ease, color 0.2s ease;
}

.gist {
  word-break: break-word;
}

.grad-blue-blue {
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.looper-wave {
  background: url(loop-wave.svg) no-repeat,
    linear-gradient(to right, #4a00e0, #8e2de2);
  background-size: cover;
  background-position: top center;
  background-position-y: -100px;
}

.grad-orange-red {
  background: #ff512f; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #dd2476,
    #ff512f
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #dd2476,
    #ff512f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.grad-purple-pink {
  background: #aa076b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #61045f,
    #aa076b
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #61045f,
    #aa076b
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.grad-teal {
  background: #5f2c82; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #49a09d,
    #5f2c82
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #49a09d,
    #5f2c82
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.grad-purple-blue {
  background: linear-gradient(180deg, #7052d4, #3b1ff3, #7052d4);
  background-size: 300% 300%;

  -webkit-animation: bgAni 30s ease infinite;
  -moz-animation: bgAni 30s ease infinite;
  -o-animation: bgAni 30s ease infinite;
  animation: bgAni 30s ease infinite;
}

.sticky-grad {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 68%,
    rgba(229, 229, 229, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 68%,
    rgba(229, 229, 229, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 68%,
    rgba(229, 229, 229, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00e5e5e5',GradientType=0 ); /* IE6-9 */
}

.ph-item {
  position: relative;
  overflow: hidden;
}
.ph-item,
.ph-item *,
.ph-item :after,
.ph-item :before {
  box-sizing: border-box;
}
.ph-item:before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsla(0, 0%, 100%, 0.35) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
}

.ph-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;
}
.ph-row div {
  height: 10px;
  margin-bottom: 7.5px;
  background-color: #ced4da;
  border-radius: 50px;
}
.ph-row .big,
.ph-row.big div {
  height: 20px;
  margin-bottom: 15px;
}
.ph-row .empty {
  background-color: hsla(0, 0%, 100%, 0);
}
.ph-col-2 {
  flex: 0 0 16.66667%;
}
.ph-col-4 {
  flex: 0 0 33.33333%;
}
.ph-col-6 {
  flex: 0 0 50%;
}
.ph-col-8 {
  flex: 0 0 66.66667%;
}
.ph-col-10 {
  flex: 0 0 83.33333%;
}
.ph-col-12 {
  flex: 0 0 100%;
}
.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.ph-avatar:before {
  content: ' ';
  display: block;
  padding-top: 100%;
}
.ph-picture {
  width: 100%;
  height: 120px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 5px;
}
@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(30%, 0, 0);
  }
}

@-webkit-keyframes bgAni {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@-moz-keyframes bgAni {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@-o-keyframes bgAni {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@keyframes bgAni {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
